.cuetcontainer {
  position: relative;
  padding: 0rem 4rem 5rem 4rem !important;
  width: 100%;
  min-width: 400px;
  margin: 0px 20px 10px 20px !important;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow-x: hidden;
  text-align: justify;
}

@media (max-width: 1000px) {
  .cuetcontainer {
    position: relative;
    padding: 10rem 0rem;
    width: 100%;
    min-width: 400px;
    margin: 40px 20px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .course_details_ctn {
    flex: 0.6;
    /* width: 60%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    gap: 1rem;
    padding: 0 2rem;
  }
}

.cuethead {
  width: inherit;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cuetcontent {
  min-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2rem;
}

.course_details_ctn {
  height: 100%;
  display: flex;
  flex-grow: 0.8;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1rem;
  padding: 0 2rem;
}

.card_ctn {
  flex: 0.3;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 0 2rem;
}

.textctn {
  width: inherit;
}

.textctn,
.addtocart {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  gap: 1rem;
}

button {
  padding: 1rem 1rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  background: #71269c;
  transition: all 0.3s ease;
  border: none;
  outline: none;
}

.textcontent {
  margin-top: 2rem;
}

.tagctn {
  padding: 0.5rem 1rem;
  background: #71269c44;
  margin: 1rem 0;
  border-radius: 4px;
}

.courseimg {
  min-height: 100px;
  width: 100%;
}

.cuetdetails {
  width: inherit;
}

.card {
  width: 100%;
  /* min-width: 400px; */
  min-width: 250px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
}

.addtocart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0;
}

.addtocart>button,
.filterctn>button {
  padding: 0.75rem 0.5rem;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: #000;
  background: #e9eaf0;
  transition: all 0.3s ease;
}