:root {
  /* --main-bg-color:; */
  /* --main-txt-color:; */
  --primary: #7b1fa2;
  --main-btnmain-color: #17081f;
  --main-btncolor: #7b1fa2;
  --main-font-family: "poppins";
}

body {
  margin: 0;
  padding: 0;
  /* font-style: ; */
  /* background-color: var(--main-bg-color); */
  background-color: #fff;
  color: var(--main-txt-color);
  overflow: scroll !important;
}

.App {
  width: 100%;
  margin: 0 auto;
}

.padding_t_10px {
  padding-top: 10px;
}

.padding_t_25px {
  padding-top: 25px;
}

.padding_b_15px {
  padding-bottom: 15px;
  margin-top: 70px;
}

.padding_tb_5_px {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  #joinbesst_media {
    height: 570px;
  }

  .padding_b_15px {
    padding-bottom: 70px;
  }
}

#joinbesst_media .carousel-button-group {
  width: 100%;
  float: right;
  margin-top: -120px;
  position: relative;
}

.magin_top_113px {
  margin-top: 20px;
}

#joinbesst_media .carousel-button-group .prevBtn {
  float: left;
}

#joinbesst_media .carousel-button-group .nextBtn {
  float: right;
}

.carousel-button-group {
  width: 10%;
  float: right;
  margin-top: -460px;
  cursor: pointer;
  z-index: 10;

}



/* media query i changed top-coureses */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .carousel-button-group {
    width: 25%;
    float: right;
    margin-top: 15px;
    display: flex;
    /* margin-top: -507px; */
  }


  .magin_top_113px {
    margin-top: 270px;
  }
}

.carousel-button-group>button {
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid var(--Primary, #71269c);
  color: #ffffff;
  background-color: #71269c;
  height: 40px;
  width: 40px;

  flex-direction: column;
  justify-items: center;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: baseline;
}

.carousel-button-group>button svg {
  vertical-align: middle;
}

.carousel-button-group .disable {
  color: #71269c;
  background-color: #fff;
}

/* blog carousel button group css */
.carousel-button-group_blog {
  width: 10%;
  float: right;
  margin-top: -525px;
}

.carousel-button-group_blog>button {
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid var(--Primary, #71269c);
  color: #ffffff;
  background-color: #71269c;
  height: 40px;
  width: 40px;
  flex-direction: column;
  justify-items: center;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: baseline;
}

.carousel-button-group_blog .disable {
  color: #71269c;
  background-color: #fff;
}

/* media query i changed blog */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .carousel-button-group_blog {
    width: 32%;
    float: right;
    margin-top: 15px;
    /* margin-top: -507px; */
  }
}

/* register_carousel button group css */
.carousel-button-group_reg {
  width: 10%;
  float: right;
  margin-top: -525px;
}

.carousel-button-group_reg>button {
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid var(--Primary, #71269c);
  color: #ffffff;
  background-color: #71269c;
  height: 40px;
  width: 40px;
}

.carousel-button-group_reg .disable {
  color: #71269c;
  background-color: #fff;
}

/* register page carousel  button group  */


.testimonials .carousel-control-prev .carousel-control-prev-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  display: block;
  bottom: auto;
  top: 65%;
  left: -30px;
  right: auto;
  margin: 0;
  z-index: 10;
  transform: translateY(-50%);
  background-image: url("../public/assets/components/button-previous.png") !important;
}


/* video::-webkit-media-controls {
  width: 20px;
}

video::-webkit-media-controls-play-button {
  background-color: #007bff;
  position: absolute;
  top: 0px;
  height: 100%;
} */


/*testing*/
.testimonials .carousel-control-next .carousel-control-next-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  display: block;
  bottom: auto;
  top: 65%;
  left: auto;
  right: -30px;
  margin: 0;
  z-index: 10;
  transform: translateY(-50%);
  background-image: url("../public/assets/components/button-next.png") !important;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .testimonials .carousel-control-prev .carousel-control-prev-icon {
    left: -9px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .testimonials .carousel-control-next .carousel-control-next-icon {
    right: -9px;
  }
}

.testimonials .carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
  background-color: #ffffff;
  border: 3px solid rgba(255, 255, 255, 0);
  outline: 2px solid rgba(255, 255, 255, 0);
}

.testimonials .carousel-indicators {
  /* display: block;
    bottom: auto;
    top: 50%;
    left: auto;
    right: 20px;
    width: 14px;
    margin: 0;
  z-index: 10;
    transform: translateY(-50%); */
  top: 110%;

  justify-content: center;
}

.testimonials .carousel-indicators .active {
  border: 3px solid #7b1fa2;
  outline: 2px solid #ffffff !important;
}

.menu-itm1 {
  font-family: var(--main-font-family);
  color: var(--main-btn-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.menu-itm1:hover {
  color: var(--main-btncolor);
}

.navbar {
  background-color: transparent;
}

.navbar.colorChange {
  background-color: #fff;
}

.menu-itm {
  font-family: var(--main-font-family);
  color: var(--main-btn-color);
  font-size: 18px;
  letter-spacing: 0.8px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}

.menu-itm:hover {
  color: var(--main-btncolor);
}

#navbarScrollingDropdown {
  font-family: var(--main-font-family);
  color: var(--main-btn-color);
  font-size: 18px;
  letter-spacing: 0.8px;
}

/* nav-btn */
.nav-btn {
  border-radius: 4px;
  color: #17081f;
  border: 1px solid #17081f;
  background: #fff;
  padding: 2px 32px 4px 28px;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 10px;
  border: 1px solid #7b1fa2;
  text-decoration: none;
}

.nav-btn:hover {
  color: #7b1fa2;
  background: #fff;
  border: 1px solid #7b1fa2;
}

.button-btn {
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  padding: 6px 20px 6px 20px;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #17081f;
  background: #7b1fa2;
  margin-bottom: 50px;
}

.play_link {
  text-decoration: none;
  color: #7b1fa2;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.button {
  border-radius: 4px;
  color: #7b1fa2;
  border: 1px solid #17081f;
  background: #fff;
  padding: 2px 32px 4px 28px;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin: 10px;
  border: 1px solid #7b1fa2;
}

.button_blog {
  border-radius: 4px;
  color: #7b1fa2;
  border: 1px solid #17081f;
  background: #fff;
  padding: 2px 32px 4px 28px;
  font-family: "Poppins";
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;

  margin: 10px;
  border: 1px solid #7b1fa2;
}

/* carousel-indigaters*/
.banner-slider .carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 15px !important;
  height: 15px;
  background-color: #7b1fa2;
  border: 3px solid rgba(255, 255, 255, 0);
  outline: 2px solid rgba(255, 255, 255, 0);
}

.banner-slider .carousel-indicators {
  display: block;
  bottom: auto;
  top: 50%;
  left: auto;
  right: 20px;
  width: 14px !important;
  margin: 0;
  z-index: 10;
  transform: translateY(-50%);
}

.banner-slider .carousel-indicators .active {
  border: 3px solid #fff;
  outline: 2px solid #7b1fa2 !important;
}

/* Carousel-siders */

.Slider {
  background: #fff;
  flex-shrink: 0;
}

.content-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

.content1 {
  color: #212121;
  text-align: center;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 55px;
}

.button-sl {
  display: flex;
  padding: 15px 30px;
  margin-left: 14rem;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: #7b1fa2;
  background: #fff;
  border: 1px solid #7b1fa2;
}

.play-btn {
  width: 42px;
  height: 42px;
  padding: 5px;
  margin-left: 15px;
}

.contnet2 {
  width: 494px;
  color: #212121;
  font-family: Poppins;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 20%;
  margin: 2px;
}

.btn_button2 {
  display: flex;
  padding: 15px 30px;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  color: #7b1fa2;
  background: #fff;
  border: 1px solid #7b1fa2;
}

.btn-primary:disabled {
  background-color: #fff;
}

/* intro */

.intro_panel {
  /* background-image: url(../public/assets/caroselimg/image\ 57.png); */
  /* mix-blend-mode: multiply; */
  background-color: #44175e;
  mix-blend-mode: multiply;
  /* Blend mode */
  /* opacity: 0.7; */
  /* mix-blend-mode: overlay; */
}

.intro_panel_l1 {
  background-color: #44175e;
  padding: 1px 0 22px 0;
  height: 667px;
  /* mix-blend-mode: multiply;
  background-image: url(./Assets/images/intro-bg.png); */
  /* opacity: 0.5; */

  /* background-color: #5a1e7dd9; */
}

.horizontal-line {
  width: 50px;
  border: 1px dashed #71269c;
}

@media (max-width: 992px) {
  .horizontal-line {
    width: 5px;
  }
}

.inrto_p {
  width: 100%;
  line-height: 30px;
  padding: 50px 30px;
  color: #e5e1dc;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  /* 150% */
}

.intro-center {
  display: flex;
  justify-items: center;
  align-items: center;
}

.intro_card {
  height: 150px;
  width: 100%;
  margin: 20px 30px;
  margin: 0px;
  overflow: hidden;
  display: flex;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .intro_panel_l1 {
    height: 1080px;
  }

  .intro_card {
    width: 80%;
  }

  .inrto_p {
    line-height: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #e5e1dc;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
    align-items: center;
  }
}

.intro_images {
  height: 100%;
  width: 100% !important;
  display: flex;
  justify-items: center;
  align-items: center;
}

.img-intro {
  margin: 0 auto;
  display: block;
}

.intro_card {
  background-color: #5a1e7d;
  margin-bottom: 50px;
  opacity: 1;
  /* mix-blend-mode: unset; */
}

/* users */
.user_1 {
  color: #fff;
  font-family: "Montserrat";
  font-size: 35px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 2px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
}

.user_name {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.8px;
}

/* top courses card */

.card-title {
  padding-top: 10px;
  overflow: hidden;
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  height: 90px;
}

.card-university-title {
  overflow: hidden;
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.card_items {
  display: flex;
  padding: 10px 10px 20px 10px;
  flex-direction: column;
  align-items: flex-start;
}

.Course_head {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Search_head {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.section-sm {
  height: 430px;
}

.Search_head_dark {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.Course_card {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--Grey-9, #e0e0e0);
  background: #fff;
  font-size: 13px;
  overflow: hidden;
  padding: 10px;
  margin-left: 10px;
  margin-top: 30px;
  height: 100%;
  width: 100%;
}

.card-container {
  display: flex;
}

.offering-heading {
  width: 40%;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }

  .offering_container {
    flex-direction: column;
    display: block !important;
    width: 100% !important;
  }

  .offering-heading {
    width: 100%;
  }
}

.Grater_then {
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  padding-top: 14px;
}

.h5 {
  color: #080808;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: "ellipsis";
  font-family: "Poppins";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.img-course {
  width: 100%;
  height: 200px;
  border-radius: 12px;
  margin-top: 20px;
}

.h6 {
  color: var(--Dark-75, rgba(8, 8, 8, 0.75));
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  font-family: "Poppins";
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.icon_col {
  margin: 5px 0 5px 0;
  color: #777474;
  display: flex;
  align-items: center;
  gap: 8px;
}

.card_body {
  width: 100%;
  /* padding-top: 10px; */
}

.margin_r_30px {
  margin-right: 30px;
}

.course_heading {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-top: 10px;
}

.course_count {
  color: #080808;
  font-size: 12px !important;
  text-transform: capitalize;
}

.course_leavel {
  color: #080808;
  font-size: 12px !important;
  text-transform: capitalize;
}

.Course_btn {
  font-family: "Poppins";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  background: #71269c;
  box-shadow: 0px 14px 24px 0px rgba(86, 53, 174, 0.2);
  cursor: pointer;
}

/* elips */
.h6 {
  color: var(--Dark, #080808);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  /* H5 */
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .elips_res {
    padding-top: 0px;
    float: right;
    margin-top: -43px;
    padding-right: 5px;
  }
}

/* search  */
.Course-tag {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: "Poppins";
  font-size: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px;
}

.Course-form {
  border-radius: 12px;
  border: 1px solid var(--Dk-80, #5a1e7d);
  background: var(--DK-60, #44175e);
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 20px 10px 20px 10px;
}

.Cor-heading {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: auto;
  width: 50%;
  padding: 10px;
  background: #44175e;
}

.cor--head {
  margin: top 2px;
  margin-bottom: 5px;
  color: #fff;
  font-size: 18px;
}

.course-result-card {
  border-radius: 10px;
  border: 1px solid rgba(123, 31, 162, 0.5);
  background: rgba(223, 223, 223, 0.2);
  /* padding: 1em; */
}

.inner-course-result-card {
  border-radius: 10px;
  border: 1px solid rgba(123, 31, 162, 0.5);
  background: rgba(223, 223, 223, 0.2);
  padding: 1em;
  color: #000;
}

.courses-available-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.course-result-college-name {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 110% */
  letter-spacing: -0.2px;
}

.college-location {
  display: flex;
  gap: 0.2em;
  align-items: center;
  justify-content: flex-start;
}

/* .course-result-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em;
} */

.course-result-titles {
  padding: 1em;
  border-bottom: 2px solid #e0e0e0;
}

/* For screens smaller than desktop */
@media (max-width: 992px) {
  .course-result-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .course-result-card {
    padding: 0.5em;
  }

  .course-result-titles {
    padding: 0.5em;
  }
}

.courses-info {
  display: flex;

  align-items: center;
  justify-content: space-between;
}

.button-cor {
  display: flex;
  padding: 3px 32px;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  background-color: #7b1fa2;
  color: #fff;
  border: 1px solid #7b1fa2;
  border-radius: 5px;
  margin-top: 15px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .button-cor {
    /* margin: auto; */
    margin-top: 15px;
    margin-left: 96px;
  }
}

/*offerimg page */

.Offer_1 {
  display: flex;
  height: 310.151px;
  padding: 90px 40px 130px 0px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.h3_head {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.lorem_1 {
  color: var(--Dark-75, rgba(0, 0, 0, 0.75));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
  text-align: justify;
  /* text-transform: capitalize; */
}

.p_head {
  color: var(--Primary, #71269c);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.p_loram {
  color: var(--Grey-4, #4f4f4f);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.height_247px {
  height: 247px;
}

.img_1 {
  border-radius: 12px;
  /* mix-blend-mode: multiply; */
  background-image: url(../public/assets/caroselimg/image\ 57.png);
  mix-blend-mode: multiply;
  /* background: url(../public/assets/cardimg/4478ff9024b692b860004cb7aaa1f43f.png), lightgray 50% / cover no-repeat; */
  width: 156px;
  height: 120px;
  border: 1px solid var(--stroke, #f1f1f1);
  /* background: #FFF; */
}

/* secoundry-carosel */

.sec_caro_h3 {
  display: flex;
  color: #fff;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  padding: 10px;
}

.secondery-item {
  border-radius: 30%;
}

/* .Caro_con_margin {} */

.Caor_img {
  border-left-style: 1px solid #080808;
  border-radius: 5px;
  width: 618px;
  height: 408px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.border-right-radius-20px {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.border-bottom-radius-20px {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.margin-l-15px {
  margin-left: -15px;
}

.bg_radious {
  border-top-right-radius: 16px;
}

.sec_caro_part {
  background-color: #fff;
  padding: 50px 70px 40px 30px;
  height: 409px;
  align-items: center;
  justify-content: center;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .sec_caro_part {
    padding: 50px 70px 40px 60px;
    height: 365px;
  }
}

.Caro_head {
  color: var(--DK-20, #17081f);
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Caor_para {
  color: #2a2724;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
  text-align: justify;
  overflow: hidden;
}

.Caor_para::first-letter {
  font-weight: 800;
  font-size: 22px;
  color: rgba(123, 31, 162);
}

.caro_btn {
  color: var(--Primary, #71269c);
  font-family: Poppins;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* border-radius: 4px; */
  margin-top: 8px !important;
  border: 1px solid var(--Primary, #71269c);
  background: #fff;
}

/* News & updates */

.back_ground {
  display: flex;
  padding: 120px 80px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  /* i changed */
  /* margin-top: 236px; */
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .back_ground {
    padding: 30px 26px !important;
  }

  .news-heading {
    padding: 14px;
  }
}

.template-layer {
  height: 558px;
  width: 100%;
}

.news_upd {
  display: flex;
  height: 558px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.gray_bg {
  background-color: #fafafa;
}

.card-back {
  background-color: #f9efff;
}

.news_card {
  display: flex;
  width: 600px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: #f9efff;
}

.report_img {
  /* width: 100%; */
  /* height: 300px; */
  border-radius: 12px;
  margin-top: 14px;
}

.card_title {
  color: var(--Grey-2, #212121);
  font-family: Poppins;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
}

.card-text {
  color: #213343;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 21.98px;
  text-align: justify;
  /* 157% */
}

.auther-name {
  color: var(--Grey-6, #757575);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.98px;
}

.news_card {
  display: flex;
  height: 558px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.news-heading {
  display: flex;
  width: 100%;
  padding-left: 8px;
  /*padding: 0px 317px 10px 0px; */
  align-items: flex-start;
  /* border-bottom: 2px solid var(--Primary, #71269C); */
  border-bottom: 2px solid #71269c;
  color: var(--Grey-2, #212121);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.news-content {
  color: #213343;
  font-family: Poppins;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 25.98px;
  /* border-bottom: 1px solid var(--Grey-9, #E0E0E0); */
}

.news_a {
  color: #213343;
  text-decoration: none;
}

.news_a:hover {
  color: #213343b9;
  text-decoration: none;
}

.auther_name {
  color: var(--Grey-4, #4f4f4f);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 21.98px;
  justify-content: space-between;
  padding-bottom: 4.5px;
  border-bottom: 1px solid var(--Grey-9, #e0e0e0);
  margin-bottom: 2px;
}

/* .news-date {} */

/* blog */

.blog_head {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.blog_card_body {
  width: 82%;
  padding-top: 5px;
}

.blog_card {
  border-radius: 12px;
  border: 1px solid var(--Grey-9, #e0e0e0);
  background: #fff;
  font-size: 13px;
  overflow: hidden;
  width: 350px;
  margin-left: 10px !important;
  height: 454px;
}

.blog_card_1 {
  border-radius: 12px;
  border: 1px solid var(--Grey-9, #e0e0e0);
  background: #fff;
  font-size: 13px;
  overflow: hidden;
  width: 350px;
  margin-left: 10px !important;
  height: 400px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .blog_card {
    height: 515px;
  }

  .margin-l-15px {
    margin-left: 0;
  }

  .overlay-text-2 {
    position: absolute;
    top: 70%;
    left: 10%;
    /* transform: translate(-50%, -50%); */
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 20px;
    width: 90%;
  }
}

.blog_card_title {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  text-overflow: ellipsis;
  /* whitespace: nowrap; */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

/* iq-page */

/* .iq_background {
  background-color: #71269C;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 649px;
  flex-shrink: 0;
  position: relative;

} */

.bg_iq {
  /* background-color: #5a1e7dd1; */
  background-color: #5a1e7d;
  /* background-image: url(http://localhost:3000/static/media/image%2057.244558c….png); */
  min-height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 100px 83px 172px 83px; */
  padding: 50px 50px 100px 50px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .bg_iq {
    /* padding: 100px 36px 200px 36px; */
    padding: 30px 30px 100px 30px;
  }
}

.in_iq_bg {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  width: 100%;
  height: 450px;
  padding: 20px;
}

.iq_head {
  display: flex;
  color: #fff;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.iq_p_head {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.span_head {
  color: #5a1e7d;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.iQ_button {
  margin: 12px;
  padding: 21px 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #cb8dbb;
  background: #fff;
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.1);
}

.abso_btn_lay {
  padding-top: 20px;
}

.iQ_button:hover {
  border-radius: 10px;
  border: 1px solid #cb8dbb;
  background: #ffc2e3;
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.1);
}

.selected {
  border: 1px solid #cb8dbb;
  background: #ffc2e3;
}

.iq_button {
  margin: 16px 50px 15px 50px;
  padding: 21px 118px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 10px;
  border: 1px solid #cb8dbb;
  background: #ffc2e3;
  box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.1);
}

.btn-sub-btn {
  justify-content: center;
  align-items: center;
}

.sub_button {
  border-radius: 4px;
  width: 100px !important;
  background: #71269c;
  color: #fff;
  margin: auto 0;
}

.sub_button:hover {
  border: 1px solid #71269c !important;
  color: #3b1452;
}

.sub_button1 {
  border-radius: 4px;
  background: #71269c;
  color: #fff;
  margin: auto 0;
}

.sub_button1:hover {
  border: 1px solid #71269c !important;
  color: #fff;
}

/* absolute_box */

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -80px;
  background-color: #FFF;
}

.absolute_box {
  display: flex;
  padding: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  border: 1px solid #000;
  background-color: #f9efff;
  border-radius: 15px;
  position: absolute;
}

/* media query i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .absolute_box {
    padding: 16px;
  }

  .btn_res {
    margin-left: 75px;
  }
}

.abso_col {
  display: flex;
  align-items: center;
  gap: 40px;
}

.margin_0_auto {
  display: block;
  width: 167px;
  margin: 0 auto;
}

.abso_p {
  color: #171717;
  font-family: Poppins;
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}

.abso_btn {
  color: var(--neutral-0, #fff);
  padding: 8px 30px;
  margin-left: 30px;
  margin-top: 13px;
  justify-content: center;
  text-align: center;
  gap: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */
  background: #71269c;
  border-radius: 4px;
  box-shadow: 0px 14px 24px 0px rgba(86, 53, 174, 0.2);
}

/* current affers */
.curr_lay {
  display: flex;
  padding: 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: #fff;
  /* border: 1px solid black; */
  margin-top: 80px;
  padding-bottom: 80px;
}

.curr_lay_2 {
  width: auto;
  height: 469px;
  /* background-color: red; */
}

/* .curr_left {} */

.curr_head {
  display: flex;
  width: 100%;
  padding: 0px 317px 10px 0px;
  align-items: flex-start;
  border-bottom: 2px solid #71269c;
  color: var(--Grey-2, #212121);
  font-family: Poppins;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .curr_head {
    padding: 0px 106px 10px 0px;
  }
}

.curr_right {
  display: flex;
  padding: 20px 68px 33px 20px;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 28px; */
  border-radius: 12px;
  border: 1px solid #e1e1e1;
  background: #fafafa;
  /* width: 333px; */
  /* height: 485px; */
}

.curr_head_2 {
  color: #213343;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 137.375% */
}

.curr_para {
  /* width: 792px; */
  color: #213343;
  font-family: Poppins;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 21.98px;
  text-align: justify;
  /* 137.375% */
}

.curr_rm {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 21.98px;
  /* width: 700px; */
  border-bottom: 1px solid var(--Grey-9, #e0e0e0);
  /* 157% */
}

.curr_r {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 21.98px;
  /* border-bottom: 1px solid var(--Grey-9, #E0E0E0); */
  /* 157% */
}

.right_head {
  color: #000;
  font-family: Poppins;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  /* 170% */
}

.right_head2 {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  /* 170% */
}

.right_p {
  color: #212121;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
  padding: 0.3em 0em;
}

.current-affairs-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  gap: 2em;
}

.current-affairs-nav>div {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0.2em 0.5em;
}

.filter-svg {
  height: 50px;
  width: fit-content;
}

.svg-container {
  background: rgba(151, 71, 255, 0.15);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.search-history {
  display: inline-flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #eadaff;
  color: #000;
  margin-right: 1em;
}

.news-date {
  background-color: white;
  border-radius: 10px;
}

.current-affair-search {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 1em;
  size: 18px;
  padding: 0.2em 0.5em;
  border-radius: 10px;
  border: 1px solid #bc8ed0;
  background: var(--Gray-White, #fff);
}

.searcch-suggestions {
  position: absolute;
  top: calc(100% + 5px);
  /* Place it below the input */
  left: 0;
  width: 100%;
  padding: 1em;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add shadow */
  z-index: 1;
  /* Ensure suggestions appear on top */
  display: none;
  /* Initially hide suggestions */
}

.current-affair-search input {
  border: none;
}

.current-affair-search input:focus {
  border: none;
}

.right_p2 {
  color: #212121;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 0.4em;
  text-decoration-line: underline;
}

.right_rm {
  color: #71269c;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
}

/* register page */

.reg_layout {
  display: flex;
  width: 100%;
  padding: 80px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-image: url("../public/assets/svg/weoffer-bg.svg");
  flex-shrink: 0;
}

.reg_layout_2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.reg_head {
  color: var(--Grey-1, #0f0f0f);
  text-align: center;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.reg_para {
  color: #213343;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.reg_btn {
  color: var(--White, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: px;
  padding: 11px 18px;
  border-radius: 4px;
  background: var(--Primary, #71269c);
  margin-top: 30px;
  text-decoration: none;

  border: 1px solid #fff;
}

/*  */

.reg_img_card {
  display: flex;
  width: 200px;
  height: 200px;
  padding: 33.379px;
  justify-content: center;
  align-items: center;
  gap: 8.345px;
  flex-shrink: 0;
  background: #fff;
  margin: 0 0.5em;
}

/* .reg_img {
  width: 150.207px;
  height: 160.111px;
} */

.reg_img_card:hover {
  -ms-transform: scale(1.01);
  transform: scale(1.12);
}

/* .reg_slider {
  display: flex;
  width: 242px;
  height: 242px;
  padding: 33.379px;
  justify-content: center;
  gap: 8.345px;
  flex-shrink: 0;
  background: #FFF;
} */

/* faq */

.accordion-button {
  color: var(--Dark-Grey, #262626);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100% !important;
  /* 155.556% */
}

/*ACCORDION PLUS ICON */
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
  border: none;
  outline: none;
}

/* ACCOODION MINES ICON */
.accordion-button:not(.collapsed)::after {
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E"); */
}

.faq_lay {
  width: 100%;
  height: 621px;
  /* background-color: #71269C; */
  position: fixed;
  /* background-image: url(../public/assets/caroselimg/image\ 57.png);
  mix-blend-mode: multiply; */
}

.faq_background {
  background-color: #5a1e7d;
  /* background-color: #71269C; */
  /* background-image: url(../public/assets/caroselimg/image\ 57.png); */
  mix-blend-mode: multiply;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 649px;
  flex-shrink: 0;
  position: relative;
  padding: 40px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .faq_background {
    margin-bottom: 60px;
  }
}

.faq_lay2 {
  /* display: flex; */
  /* width: 100%; */
  /* flex-direction: column; */
  /* justify-content: center;
  align-items:flex-start; */
  gap: 16px;
}

.faq_head {
  color: #fff;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
}

.faq_para {
  /* color: #FFF; */
  font-family: Poppins;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
  /* color: #213343; */
  /* text-align: center; */
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  background-color: #e3d4eb;
}

.accordion_header {
  color: var(--Dark-Grey, #262626);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.faq_header {
  color: var(--Primary, #71269c);
  font-family: Poppins;
  font-size: 18px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.faq_margin {
  border-color: RED;
}

.faq__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -80px;
}

.faq_box {
  /* display: flex; */
  padding: 18px;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px;
  border: 1px solid #000;
  border-radius: 8px;
  background: var(--White, #fff);
  width: 100%;
  height: 135px;
  position: absolute;
}

.faq_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.faq_col {
  align-items: center;
  gap: 40px;
}

.faq_box_name {
  color: var(--Grey-1, #0f0f0f);
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.faq_p {
  color: var(--Grey-1, #0f0f0f);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-top: 10px;
}

.faq__p {
  color: var(--Grey-1, #0f0f0f);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}

.bg-lite {
  background-color: #fff;
  color: #32054d;
  border: 1px solid #71269c;
}

/* .i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .faq_box {
    height: 250px;
    margin-top: 300px;
    margin-bottom: 200px;
  }
}

/* .faq_btn_wa {
  color: var(--Primary, #71269C);
  text-align: center;
  gap: 10px;
  height: 42px;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  border-radius: 4px;
  border: 1px solid var(--Primary, #71269C);
  color: #71269C;
  margin-top: 30px;
  margin-left: 60px;
} */

/* .faq_btn {
  
} */
.sub_button_faq_wa {
  display: inline-flex;
  padding: 8px 18px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
}

.sub_button_faq {
  display: flex;
  padding: 8px 18px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  background: #71269c;
  color: #fff;
}

/* footer */
.footer_bak {
  /* width: 100%;
  height: 657px; */
  display: -webkit-inline-box;
  flex-shrink: 0;
  background: #17081f;
  /* margin-top: 150px; */
  /* border: 1px solid black; */
}

.subscription-buttons-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1em 0;
}

.footer-row {
  flex-direction: row;
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}

#footer-style {
  display: flex;
  justify-content: space-evenly;
}

/* For tablets and larger devices (Bootstrap MD breakpoint) */
@media (max-width: 991.98px) {
  #footer-style {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

/* For smaller devices (Bootstrap XS breakpoint) */
@media (max-width: 575.98px) {
  #footer-style {
    display: flex;
    flex-direction: column;
  }
}

.qr {
  height: 120px;
  width: 120px;
  margin: 5px;
}

.app-download {
  height: 55px;
  width: 195px;
  margin: 5px;
}

.footer_layout {
  width: 100%;
  height: 657px;
  flex-shrink: 0;
  background: #17081f;
}

.footer_con {
  /* margin-top: 150px; */
  padding: 24px 10px 10px 25px;
}

.footer_heading {
  color: var(--fill-color-grey-3, #dddfe3);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* text-decoration: underline; */
  width: 200px;
  padding-bottom: 10px;
  border-bottom: 1px solid #323536;
  align-items: flex-start;
  /* gap: 10px; */
  justify-content: center;
  margin-left: 55px;
}

.bg_color {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  font-size: 14px;
}

.footer-col {
  width: 100%;
}

.spacing {
  width: 24px;
  height: 22.316px;
}

.store_head {
  padding: 10px 10px;
}

/* .qr_img {
  margin: 10px 10px;
  float: right;
}

.span_img{

  margin-right: 5px;
  clear: left;
} */

.foot_box {
  float: left;
  width: 48.33%;
  padding: 15px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.location {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.icon_foot {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: center;
}

.margin-top-97 {
  margin-top: 97px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .margin-top-97_res {
    margin-top: 170px;
  }

  .we-offer {
    background-image: url("../public/assets/svg/weoffer-bg.svg");
    padding: 1em 2em !important;
  }
}

.card_image {
  width: 215px;
  height: 151px;
  border-radius: 10px;
}

/* i changed */
@media only screen and (min-width: 320px) and (max-width: 600px) {
  .card_image {
    width: 315px;
  }
}

.we-offer {
  background-image: url("../public/assets/svg/weoffer-bg.svg");
  padding: 1em 4em;
}

/* i changed */
.card_res {
  padding: 8px;
}

/* playstore & appstore */
.app-icons {
  display: flex;
  align-items: center;
}

.vertical {
  border-radius: 4px;
  background: #fff;
  /* width: 70px; */
  /* height: 50px; */
  /* flex-direction: column; */
}

.android {
  width: 150px;
}

.apple {
  width: 150px;
}

/* register */
.reg_card {
  border-radius: 12px;
  background: #fff;
  font-size: 13px;
  overflow: hidden;
  width: 242px;
  height: 242px;
}

/* popup */

.hide {
  display: none !important;
}

#mydiv {
  position: absolute;
  z-index: 100;
  background-color: #f9efff;
  text-align: center;
  border: 1px solid #d3d3d3;
  width: 345px;
  /* height: 252px; */
  border-radius: 6px;
  padding: 5px;
}

.card-body {
  margin-top: 8px;
}

.h2_head {
  line-height: 30px;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
}

.h4_head {
  line-height: 16px;
  font-size: 16px;
  font-weight: 400;
  font-family: Poppins;
  margin-left: -44px;
  margin-bottom: 15px;
}

#mydivheader {
  cursor: move;
  z-index: 10;
  color: #000;
  float: left;
  margin-left: 15px;
  /* align-items: start;
  justify-content: flex-start; */
}

.card_drag {
  height: 200px;
  width: 416px;
  /* background-color: #f9efff; */
  background-color: #f7f5fb;
  border-radius: 8px;
}

.cardHeader {
  color: #000;
  font-size: 15px;
}

.input_f {
  border: 1px dashed #71269c;
  background-color: #fff;
  padding: 6px;
  border-radius: 8px;
  padding-right: 90px;
}

.btn_sub {
  padding: 10px 24px;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--Primary, #71269c);
  color: #fff;
  font-size: 12px;
  font-family: Poppins;
  float: left;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 0%;
  padding: 12px 16px;
  transform: translate(0%, -50%);
}

.close:hover {
  background: #bbb;
}

/* sign in css */
.Sign_in_bg {
  width: 100%;
  height: 776px;
  flex-shrink: 0;
  background-image: url("../public/assets/caroselimg/sign_in_png.png");
  background-color: #7b1fa2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  /* opacity: 0.5; */
}

.Sign_in_box {
  width: 706px;
  height: 597px;
  flex-shrink: 0;
  background: #fff;
  /* margin: auto; */
  padding: 10px;
}

.sign_in_head {
  color: #000;
  /* color: #080808; */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px;
  text-align: center;
  /* margin: auto; */
  /* margin-left: 140px; */
}

.mail_img {
  color: var(--gray-500, #8c94a3);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  border: 1px solid black;
}

.select_option {
  /* width: 706px; */
  /* width: 597px; */
  background: #fff;
  /* margin: auto; */
  text-align: center;
  padding: 15px;
  padding-bottom: 30px;
  /* background-image: url("../public/assets/caroselimg/Ellipse\ 2654.png"); */
}

.select_option_type {
  /* background-color: red; */
  /* padding: 3px;
  justify-content: space-between; */
  padding: 34px;
  margin-right: -11px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.14px;
}

.field_box {
  padding-bottom: 50px;
  margin-left: 15px;
  margin-right: 15px;
}

.lable_name {
  color: #080808;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.16px;
  /* margin-left: 20px; */
}

.input_field_box {
  width: 625px;
  height: 55px;
  border: 1px solid black;
}

.placeHolederName {
  color: rgba(0, 0, 0, 0.5);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.sign_up_btn {
  text-align: center;
}

.sign_up_next_btn {
  width: 298px;
  height: 60px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 30px;
  border-radius: 4px;

  /* background: #8C94A3;
  color: #000000; */
}

.sign_in_btn {
  width: 298px;
  height: 60px;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  background-color: #7b1fa2;
  color: #fff;
  border: 1px solid #7b1fa2;
  border-radius: 8px;
  text-align: center;
}

.devider_layout {
  /* width: 625px; */
  height: 1px;
  background: #e9eaf0;
  margin-bottom: 30px;
  margin-left: 40px;
  margin-right: 40px;
}

.devider_text {
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  opacity: 0.7;
}

.sign_in_shareOptions {
  display: flex;
  width: 184px;
  padding: 0px 12px 0px 12px;
  align-items: center;
  gap: 12px;
  /* border: 1px solid #e9eaf0; */
  background: #fff;
  width: 100%;
  height: 50px;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.sign_in_content_layout {
  border: 1px solid black;
  padding: 6px;
  margin: auto;
  width: 156px;
  text-align: center;
}

.img-sign-options {
  float: left;
  border: 1px solid #000;
  padding: 5px;
}

.span_login_msg {
  text-align: center;
}

.span_msg {
  color: #080808;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.16px;
}

.span_login {
  color: #71269c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.16px;
}

.otpContainer {
  margin: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.otpInput {
  width: 10%;
  padding: 6px;
}

.resend_otp_msg {
  /* float: right; */
  text-align: right;
  margin-top: -34px;
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.14px;
}

.skip_msg {
  text-align: right;
  margin-top: 7px;
  margin-right: 30px;
}

.skip_link_msg {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.14px;
  text-decoration-line: underline;
}

.sign_in_btns {
  width: 298px;
  height: 60px;
  border-radius: 4px;
  background: #8c94a3;
  color: #000000;
  margin-bottom: 30px;
  /* margin-right: 20px; */
}

.log_in_otp_btn {
  /* display: flex; */
  width: 298px;
  height: 60px;
  /* padding: 3px 32px; */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  background-color: #7b1fa2;
  color: #fff;
  border: 1px solid #7b1fa2;
  border-radius: 8px;
  text-align: center;
}

.current-affairs-container {
  padding: 2em;
  display: flex;
  flex-direction: column;
}

.current-filter-button {
  border-radius: 10px;
  border: 1px solid #bc8ed0;
  background: var(--gray-white, #fff);
  display: flex;
  padding: 0.4em 1em;
  justify-content: center;
  align-items: center;
  gap: 2em;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 600;
}

.current-sort-container {
  display: flex;
  justify-content: space-between;
}

.current-filter-search {
  display: flex;
  gap: 1em;
  align-content: center;
}

.current-search-button {
  border-radius: 10px;
  border: 1px solid #bc8ed0;
  background: var(--gray-white, #fff);
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0.4em 6em 0.4em 0.4em;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.75);
}

.current-sort-button {
  display: flex;
  display: flex;
  padding: 0.4em 0.6em;
  justify-content: center;
  align-items: center;
  gap: 4em;
  border-radius: 10px;
  border: 1px solid #bc8ed0;
  background: var(--gray-white, #fff);
  color: #000;
}

.current-sort-container {
  display: flex;
  gap: 1em;
  align-items: center;
}

.current-affairs-content-container {
  display: flex;
  justify-content: space-between;
}

.current-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em 0;
}

.current-sidebar-container {
  width: 25%;
  padding: 2em 0;
}

.current-sidebar {
  border-radius: 15px;
  border: 1px solid rgba(123, 31, 162, 0.5);
  background: #fff;
  padding: 1em;
}

.current-sidebar li {
  list-style: none;
}

.current-see-more {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 27px */
  letter-spacing: 0.36px;
  width: fit-content;
  background: #fff;
  padding: 0;
}

.sidebar-head {
  color: #080808;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
  letter-spacing: 0.4px;
  padding: 1em 0;
  border-bottom: 1px solid rgba(123, 31, 162, 0.5);
}

/* contact Us  */
.Contact_heading {
  height: 175px;
  background: #dcd4e0;
  width: 100%;
}

.blog-bg {
  height: 175px;
  background: #7b1fa229;
}

.Contact_content {
  color: var(--Dark, #080808);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 50px;
}

.author {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-content {
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.right-pane {
  width: 35%;
  padding-top: 24px;
}

.blog-preview-container>*:not(:last-child) {
  border-bottom: 4px solid rgba(123, 31, 162, 0.2);
}

.blog-preview {
  display: flex;
  padding: 24px 0;
  gap: 24px;
  align-items: center;
}

.blog-date {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: -0.21px;
  text-transform: uppercase;
}

.blog-previw-title {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 162.5% */
}

.blog-preview-container {
  padding: 24px;
}

.blog-preview-image {
  height: 120px;
  width: 120px;
}

.blog-img {
  width: 100%;
  height: 300px;
}

.class-img {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.author-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.get-started-container {
  padding: 0 24px;
}

.get-started {
  border-radius: 4px;
  background: #7b1fa2;
  color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-content: center;
  text-align: center;
}

.get-started>h3 {
  color: #fff;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 137.5% */
}

.get-started-button {
  border-radius: 4px;
  background: #fff;
  color: #7b1fa2;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 150% */

  box-shadow: 0px 14px 24px 0px rgba(86, 53, 174, 0.2);
}

.testimonials-button {
  box-shadow: 0px 14px 24px 0px rgba(86, 53, 174, 0.2);
  padding: 15px 30px;
  font-size: 20px;
  font-weight: 600;
}

.video-container {
  border-radius: 10px;
  background: #fff;
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 01 */
  box-shadow: 0px 2px 10px 0px rgba(113, 38, 156, 0.15);
}

.video-description {
  padding: 12px 0;
  display: flex;
  justify-items: center;
  justify-content: center;
  gap: 4px;
}

.heading-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.video-description>.name {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 110% */
  white-space: nowrap;
}

.video-description>.course {
  color: rgba(0, 0, 0, 0.75);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 137.5% */
  letter-spacing: -0.16px;
}

.testimonial-card-body {
  display: flex;
  align-content: flex-start;
  flex-direction: column;
}

.user-content {
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
}

.testimonials-heading {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
  text-align: center;
}

.testimonials-container {
  display: flex;
  padding: 4rem;
  gap: 24px;
  justify-items: center;
  justify-content: space-evenly;
}

.testimonials-col {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 28%;
}

.testimonials-poster {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  padding: 4em;
  gap: 2em;

  background: #7b1fa211;
  align-items: center;
}

.news-page-container {
  padding: 4em 8em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
}

.news-page-button {
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  background: #eadaff;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.2em 0.8em;
  white-space: nowrap;
}

.load-news {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #7b1fa2;
  border-radius: 5px;
  border: 1px solid #7b1fa2;
  background: #fff;
  width: fit-content;
}

.news-page-title {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.news-page-details {
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.24px;
}

.news-page-1st-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2em;
}

.news-page-card {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  border-radius: 20px;
  border: 1px solid #d7bce3;
  background: #fff;
}

.testimonials-card {
  border-radius: 10px;
  border: 1px solid rgba(123, 31, 162, 0.1);
  background: #fff;
  box-shadow: 0px 2px 10px 0px rgba(113, 38, 156, 0.15);
}

.testimonials-section {
  padding: 2em;
}

div.testimonials-section {
  border-bottom: solid 2px rgba(123, 31, 162, 0.1);
}

.testimonial-name {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 137.5% */
  margin-bottom: 0;
}

.testimonial-course {
  color: rgba(0, 0, 0, 0.75);

  /* Body Medium/400 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.14px;
}

/* .blog-faq{
  display: flex;
} */
.auther-name {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.24px;
}

.blog-container {
  display: flex;
  padding: 24px;
  gap: 10px;
}

.Contact_slashContent {
  color: var(--Dark, #080808);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: capitalize;
}

.Row_heading {
  color: #080808;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.heading_bar {
  width: 50px;
  height: 2px;
  flex-shrink: 0;
  background: #7b1fa2;
  margin-bottom: 20px;
}

.contact_para {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  padding-bottom: 20px;
  text-align: justify;
}

.mb-3 {
  margin-bottom: 1.3rem !important;
}

.contact_location_logo {
  padding-right: 8px;
}

.contact_location_para {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
  padding-bottom: 20px;
  display: flex;
}

.whatsApp_content {
  color: rgba(0, 0, 0, 0.75);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 166.667% */
  padding-bottom: 20px;
}

.send_msg_btn {
  display: flex;
  padding: 15px 40px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  background-color: #7b1fa2;
  color: #fff;
  border: 1px solid #7b1fa2;
  border-radius: 6px;
  width: fit-content;
  box-shadow: 0px 14px 24px 0px rgba(86, 53, 174, 0.2);
}

.contact_location_map {
  width: 100%;
  height: 424px;
  background: url("../public/assets/caroselimg/59920b02a0886e1c86d95b5b7ad1ef78.png") center / cover no-repeat;
}

.subjects-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.subject-card {
  border-radius: 10px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;

  box-shadow: 0px 2px 10px 0px rgba(113, 38, 156, 0.15);
}

.subject-name {
  color: var(--Dark-75, rgba(0, 0, 0, 0.75));
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.25px;
  text-transform: capitalize;
}

/* password */
a {
  color: #333;
}

a:hover {
  color: #fff;
}

.forget_msg {
  text-align: right;
  margin-top: -45px;
  margin-right: 30px;
}

.forget_link_msg {
  color: #7b1fa2;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
  letter-spacing: -0.14px;
  text-decoration-line: underline;
}

.span_register_msg {
  color: #71269c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.16px;
}

.mt-40 {
  margin-top: 7%;
}

.stat1 {
  color: #080808;

  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
}

.align_center.row {
  width: 100%;
}

.Take-sub_button {
  border-radius: 4px;
  width: "80%" !important;
  background: #71269c;
  color: #fff;
  margin: auto 0;
}

button.primary.btn.btn-lg.btn.btn-primary {
  background: #71269c;
}

button.whatsapp.btn.btn-lg.btn.btn-primary {
  background: white;
  color: #71269c;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
  button.primary.btn.btn-lg.btn.btn-primary {
    background: #71269c;
    float: right;
    position: relative;
    bottom: 65px;
    /* width: 30%; */
  }

  button.whatsapp.btn.btn-lg.btn.btn-primary {
    background: white;
    color: #71269c;
    /* width: 60%; */
  }

  .footer_bak {
    margin-top: 50px;
  }

  .loginotpbutton {
    font-size: medium;
  }

  .cards-container.card {
    /* height: 516px !important; */
    height: 100%;
    margin-top: 29%;
  }

  .otpsize {
    width: 40px !important;
    height: 40px !important;
  }

  .align_center.row {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  button.primary.btn.btn-lg.btn.btn-primary {
    background: #71269c;
    float: right;
    position: relative;
    /* bottom: 150%; */
    width: 30%;
  }

  button.whatsapp.btn.btn-lg.btn.btn-primary {
    background: white;
    color: #71269c;
    width: 60%;
  }

  .footer_bak {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  button.primary.btn.btn-lg.btn.btn-primary {
    background: #71269c;
    float: right;
    position: relative;
    /* bottom: 150%; */
    width: 30%;
  }

  button.whatsapp.btn.btn-lg.btn.btn-primary {
    background: white;
    color: #71269c;
    width: 60%;
  }

  .footer_bak {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  button.primary.btn.btn-lg.btn.btn-primary {
    background: #71269c;
    float: right;
    position: relative;
    bottom: 150%;
    width: 30%;
  }

  button.whatsapp.btn.btn-lg.btn.btn-primary {
    background: white;
    color: #71269c;
    width: 60%;
  }

  .footer_bak {
    margin-top: 50px;
  }
}

.align_center {
  margin: 0 auto;
}

.custom-card {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

.custom-image {
  width: 100%;
  max-width: 300px;
  /* Adjust the max-width to decrease image size */
  height: auto;
  border-radius: 10px;
  margin: 0 10px;
  /* Add margin to separate images */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_heading {
  color: var(--Dark, #080808);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.quote-container {
  position: relative;
  padding: 45px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.quote-icon {
  font-size: 1.5em;
  color: #007bff;
}

.top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.bottom-right {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.down-arrow {
  position: absolute;
  bottom: 20;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  /* Adjust the width as needed */
  height: auto;
}

.down-arrow-about {
  position: absolute;
  bottom: 20;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  /* Adjust the width as needed */
  height: auto;
}

.name-role {
  position: absolute;
  bottom: -90px;
  /* Adjust the spacing as needed */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.name-role>p:nth-child(1) {
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}

/* Apply styles to the second <p> child */
.name-role>p:nth-child(2) {
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.75);
}

.intro_plat {
  background-image: url("./Assets/images/intro-bg.png") !important;
}

.company-container {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 193px;
  padding: 20px;

  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.company-logo {
  display: flex;
  width: 193px;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.offering_container {
  display: flex;

  justify-content: center;
  align-items: center;
}

.offering_heading {
  width: 33%;
  padding: 24px;
}

@media (max-width: 1900px) {
  /* Adjust styles for screens smaller than 768px */
  /* .offering_container {
    flex-direction: column;
  } */

  /* Add more responsive styles as needed */
}

@media (max-width: 768px) {
  /* Adjust styles for screens smaller than 768px */
  /* .offering_container {
    flex-direction: column;
  } */

  /* Add more responsive styles as needed */
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper {
  position: relative;
  margin: 0 10px;
}

.images-wrapper {
  position: relative;
  padding: 0 0 4em 4em;
}

.about-us-preview {
  display: flex;
  gap: 2em;
  padding: 2em;
  justify-content: center;
}

.floating-image {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 50%;
  max-height: 50%;
}

@media (max-width: 992px) {
  .about-us-preview {
    flex-direction: column;
  }
}

.custom-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.abt_section {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.about-stats-card {
  position: absolute;
  top: 4em;
  left: -2em;
  padding-top: 16px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.05);
  width: fit-content;
}

@media (max-width: 992px) {
  .about-stats-card {
    display: none;
  }
}

.about-button {
  border-radius: 4px;
  border: white solid;
  background: none;
  padding: 0.5rem;
}

.about-button:hover {
  border: none;
  background: #71269c;
}

@media (max-width: 768px) {

  /* Adjust styles for screens smaller than 768px */
  .about_heading {
    font-size: 24px;
  }

  /* Add more responsive styles as needed */
}

@media (max-width: 576px) {

  /* Adjust styles for screens smaller than 576px */
  .about_heading {
    font-size: 20px;
  }

  /* Add more responsive styles as needed */
}


.website-link {
  color: black;
}

.website-link:hover {
  color: #71269c !important;
}

/* Add more media queries and responsive styles as needed */