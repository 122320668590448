 .carousel-container {
   display: flex;
   flex-direction: column;
   gap: 20px;
 }

 .auth-btn-container {
   display: flex;
   gap: 10px;
 }

 .header-item {
   text-align: center;
 }

 .nav-btns-container {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 8px;
   list-style: none;
   margin: 0 !important;
   padding: 0 !important;
 }


 .notification {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   background-color: red !important;
   color: white;
   font-size: 12px;
   width: 18px;
   height: 18px;
   border-radius: 50%;
   position: absolute;
   right: -3px;
   top: -3px;
 }

 @media (max-width: 765px) {
   .auth-btn-container {
     flex-direction: column;
   }
 }

 /* pulse animation */
 .btn-blink {
   width: 6px;
   height: 6px;
   border-radius: 100%;
   display: inline-block;
   background-color: red;
   margin-bottom: 2px;
   outline: none;
 }

 .rec-animation {
   animation-name: pulse;
   animation-duration: 2s;
   animation-iteration-count: infinite;
   animation-timing-function: linear;
 }

 @keyframes pulse {
   0% {
     box-shadow: 0px 0px 3px 0px rgba(173, 0, 0, 0.3);
   }

   65% {
     box-shadow: 0px 0px 3px 8px rgba(173, 0, 0, 0.3);
   }

   90% {
     box-shadow: 0px 0px 3px 8px rgba(173, 0, 0, 0);
   }
 }