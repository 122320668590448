.switch-course-container {
   display: flex;
   margin-top: 0.75rem;
   justify-content: space-between;
   padding: 0.5rem 0;
}

.question-number-container {
   background: #F0F0F0;
   border-radius: 8px;
   position: sticky;
   top: 80px;
   z-index: 999;
   display: flex;
   gap: 0.25rem;
   overflow-x: auto;
}

.question-ans-chapter-container {
   margin-top: 0.5rem;
   display: flex;
   align-items: flex-start;
   gap: 0.5rem;
}

.paper-side {
   flex: 1;
}

.paper-container {
   padding: 1rem;
}

/* .question-ans-container-wrapper{
   max-height: 700px;
   overflow: auto;
} */

.column-two-chapter-lang {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   position: sticky;
   top: 140px;
   z-index: 1;
}

.lang-container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 0.75rem;
}

.chapters-container,
.lang-container {
   flex: 0.25;
   min-width: 225px;
   padding: 0.75rem 1rem;
}

.toggle-chapter-btn {
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   font-size: 14px;
   width: 35px;
   height: 35px;
   background: #d5d5d5;
   border-radius: 100%;
}

.hidden-chapters-container {
   position: sticky;
   top: 140px;
}

.chapters-btn-container {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   max-height: 475px;
   overflow: auto;
}

.chapters-btn-container>button {
   border: 1px solid #8115b1;
   color: #8115b1;
   outline: none;
   padding: 0.5rem 1rem;
   border-radius: 8px;
   background-color: white;
   min-height: 40px;
}

.chapter-name {
   white-space: nowrap;
   width: 300px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.chapters-btn-container>button:hover,
.chapters-btn-container>button:focus {
   background-color: #9a18d1;
   color: white;
}

.active-chapters-btn {
   background-color: #8115b1 !important;
   color: white !important;
}

.question-answer-container {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   background: #f4dcff;
   color: #000000;
   margin-bottom: 1rem;
   padding: 1rem;
   border-radius: 8px;
}

/* .eng-question,
.eng-answer{
   background-color: #c08ad8;
   border-radius: 8px;
   padding: 0.25rem;
}

.other-question,
.other-answer{
   background-color: #d9b9e8;
   border-radius: 8px;
   padding: 0.25rem;
} */

.subjective-qusetion-container,
.subjective-answer-container {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
}

.subjective-qusetion-container p,
.subjective-answer-container p {
   margin: 0;
}

.subjective-answer-container {
   padding: 0.5rem;
   border-radius: 8px;
}

.dropdown-btn-lang {
   /* background-color: #f9f9f9; */
   background-color: #8115b1;
   outline: none;
   border: none;
   padding: 0.35rem 1rem;
   border-radius: 8px;
}

/*alternate color*/
/* .question-answer-container:nth-child(odd){
   background-color: #8e2cb9;
   color: #ffffff;
}

.question-answer-container:nth-child(odd)>.subjective-answer-container{
   background-color: #a75bc8;
} */


.dropdown-btn-lang:hover,
.dropdown-btn-lang:focus {
   /* background-color: #FFFFFF; */
   background-color: #8115b1;
}

.fallback-message-paper {
   height: 58vh;
   display: flex;
   align-items: center;
   justify-content: center;
}

.limit-height {
   min-height: 100% !important;
}

.light-bg-img {
   background-image: url("../../Assets/images/translusent-logo.png");
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
}

.prev-nxt-btn-container {
   display: flex;
   justify-content: space-between;
   padding: 1rem 0;
}

@media(max-width: 950px) {
   .question-ans-chapter-container {
      flex-direction: column-reverse;
      align-items: stretch;
   }

   .chapters-container {
      position: relative;
      inset: 0;
   }

   .chapter-name {
      min-width: 150px;
   }

   .lang-container {
      flex-direction: row;
      padding: 0.5rem 1rem;
      align-items: center;
      justify-content: space-between;
   }

   .column-two-chapter-lang {
      gap: 0.1rem;
      top: 135px;
   }

   .chapters-btn-container {
      flex-direction: row;

   }
}

@media(max-width: 580px) {
   .switch-course-container {
      flex-direction: column;
      gap: 0.5rem;
      align-items: flex-end;
   }

   .selected-course {
      align-self: flex-start;
   }
}