.testimoniyal_container {
    max-width: 1340px;
    width: 100%;
    height: auto;
    margin: auto;
    padding: 0 13px;
}

.sec-title {
    margin-bottom: 50px;
    padding-bottom: 15px;
    position: relative;
}

.sec-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100px;
    height: 3px;
    background: #000;
}

.sec-title {
    font-size: 60px;
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: var(--blue);
}

.sec-title p {
    font-size: 18px;
    line-height: 28px;
}

.testimonial-area {
    background: #5A1E7D;
    position: relative;
    z-index: 2;
    padding: 50px 0;
}

.testimonial-area .owl-carousel {
    overflow: hidden;
    padding: 0 20px;
    margin: 0px -10px;
    padding-right: 40px;
}

.testimonial-area .owl-stage-outer {
    padding: 30px 50px;
    margin-left: -34px;
    width: calc(100% + 100px);
}

.single-testimonial {
    border: 7px solid #fff;
    text-align: center;
    border-radius: 45px;
    position: relative;
    z-index: 2;
}

.single-testimonial p {
    color: #fff;
    font-size: 16px !important;
    line-height: 18px;
    padding: 50px;
    padding-bottom: 30px;
    position: relative;
    z-index: 3;
}

.single-testimonial::before {
    content: "";
    position: absolute;
    left: -35px;
    top: -35px;
    border-radius: 50%;
    background: url(../../../../../public/assets/testimonials/quote.png) no-repeat #5A1E7D;
    background-size: 60%;
    width: 126px;
    height: 100px;
    transform: rotate(180deg);
    background-position: 34px 15px;
}

.single-testimonial::after {
    content: "";
    position: absolute;
    right: -35px;
    bottom: -34px;
    border-radius: 50%;
    background: url(../../../../../public/assets/testimonials/quote.png) no-repeat #5A1E7D;
    background-size: 60%;
    width: 126px;
    height: 100px;
    background-position: 34px 19px;
}

.round {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.round-1::before {
    content: "";
    position: absolute;
    left: 88px;
    top: -7px;
    width: 50px;
    height: 7px;
    background: #fff;
    border-radius: 30px;
}

.round-1::after {
    content: "";
    position: absolute;
    left: -7px;
    top: 62px;
    width: 7px;
    height: 50px;
    background: #fff;
    border-radius: 30px;
}

.round-2::before {
    content: "";
    position: absolute;
    right: 87px;
    bottom: -7px;
    width: 50px;
    height: 7px;
    background: #fff;
    border-radius: 30px;
    z-index: 1;
}

.round-2::after {
    content: "";
    position: absolute;
    right: -7px;
    bottom: 62px;
    width: 7px;
    height: 50px;
    background: #fff;
    border-radius: 30px;
    z-index: 1;
}

.client-video {
    padding-right: 15px;
}

.client-info {
    position: relative;
    z-index: 3;
}

.client-info a {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
    font-size: 22px;
}

.client-info {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding-bottom: 50px;
}

.client-info h6 {
    color: #000;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
}

.client-info span {
    display: inline-block;
    color: #fff;
    font-size: 12px;
}

.sec-title.white-title {
    color: #fff;
}

.title-success {
    display: flex;
    color: #fff;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    padding: 10px;
}

.owl-dots button {
    background: #fff !important;
    width: 10px !important;
    height: 10px;
    border-radius: 26px;
    margin: 0 5px;
    transition: 0.3s;
}

.owl-dots {
    text-align: center;
    margin-top: 50px;
}

.owl-dots button.active {
    width: 30px !important;
}


.text-paragraph {
    font-size: 18px !important;
}

.intro-video {
    height: 450px;
    width: 500px;
    border-radius: 25px;
    min-height: 400px;
}


@media (max-width: 768px) {
    .title-success {
        font-size: 28px;
    }

    .single-testimonial p {
        font-size: 14px;
        padding: 20px;
    }

    .intro-video {
        height: 400px;
        width: 100%;
        border-radius: 25px;
        min-height: 400px;
    }
}

@media (max-width: 576px) {
    .single-testimonial p {
        font-size: 12px !important;
        padding: 10px;
    }

    .title-success {
        font-size: 24px !important;
    }

    .intro-video {
        position: sticky;
        z-index: 9999;
        margin-top: 50px;
        height: 400px;
        width: 100%;
        border-radius: 25px;
        min-height: 400px;
    }
}




.carousel-indicators li {
    background-color: white;
}

.carousel-indicators .active {
    background-color: #5A1E7D;
}

.dot_style {
    bottom: 0px !important;
}


/* Expert */

.team-section {
    text-align: center;
    padding-bottom: 50px;
    background-color: #f5f5f5;
    /* Light gray for the bottom half */
}

.team-header {
    background-color: #5A1E7D;
    /* Green background like in the image */
    padding: 100px 0;
    /* Increase padding for larger green section */
    position: relative;
}

.team-heading {
    font-size: 36px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
}

.team-subtitle {
    font-size: 16px;
    color: #e0e0e0;
    /* Lighter shade for the subtitle */
}

.team-row {
    display: flex;
    justify-content: center;
    margin-top: -90px;
    /* This pulls the team cards up to overlap the green section */
}

.team-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 15px;
    width: 90%;
    /* Adjust width to fit better */
}

.team-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.team-name {
    font-size: 22px;
    font-weight: bold;
    margin-top: 15px;
}

.team-role {
    font-size: 16px;
    color: #555;
}

.team-description {
    font-size: 14px;
    color: #777;
    margin: 10px 0;
}

@media (max-width: 768px) {
    .team-row {
        flex-direction: column;
        align-items: center;
    }
}

.ads_img {
    width: 100%;
    position: relative;
    /* margin-top: 60px; */
    /* padding: 66px 0 39px; */
    text-align: center;
    background: url(../../../../../public/assets/image_change_dashboard//plain_background.png);
    background-size: cover;
    color: white;
    /* Ensure text is white to contrast with the dark overlay */
}

@media only screen and (max-width: 767px) {
    .banner-text {
        margin-top: 80px;
    }

    .form_query {
        padding-top: 10px !important;
        margin-left: 0px !important;
    }

    .form_query_content {
        padding-top: 100px !important;
    }
}

.form_query {
    padding-top: 100px;
    margin-left: 40px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: 71269c !important;
    border-color: white !important;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff !important;
    background-color: 71269c !important;
    border-color: white !important;
    box-shadow: none;
}

.button_loading:disabled {
    background-color: #5A1E7D !important;
}