.btn-que-mark {
  content: "";
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #34b601;
  position: absolute;
  top: 20px;
  right: 1px;
}

.testCard {
  background-color: #FFFFFF;
}

.pdfviewer {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 10px 80px;

}

.questionOption {
  background-color: #FFFFFF;

}

/* .pdf-file{
  border: 2px dotted black;
} */
.ractangle {
  width: 35px;
  height: 42px;
  clip-path: polygon(0 0%, 100% 20%, 100% 70%, 0%100%);
  color: "white";
  background: #e64500;
}

.sweet-warning {
  background-color: #2a036d;
}

.ractangle_Instruction2 {
  width: 25px;
  height: 25px;
  background: #e64500;
  border: "1px solid #792501";

  clip-path: polygon(0 0%, 100% 20%, 100% 85%, 0%100%);
}

.ractangle_Instruction1 {
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right top,

      #eeeeee,
      #b0acac);
  border: 2px solid rgb(186, 184, 184);
  border-radius: 5px;
}

.ractangle_Instruction3 {
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right top,

      #1e7032,
      #34b601);
  background: #039815;
  clip-path: polygon(0 0%, 100% 20%, 100% 85%, 0%100%);
}

.ractangle_Instruction4 {
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right top,

      #604b93,
      #2a036d);
  border-radius: 50%;
}

.ractangle_Instruction5 {
  width: 25px;
  height: 25px;
  background-image: linear-gradient(to right top,

      #604b93,
      #2a036d);
  border-radius: 50%;
}

.ractangle1 {
  width: 35px;
  height: 42px;
  background: #34b601;
  clip-path: polygon(0 0%, 100% 20%, 100% 70%, 0%100%);
  color: "white";
}

/* .tableHeader {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
} */
.summury-table {
  width: 100%;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tableHeader th {
  background-color: #fafafa;
  border: 1px solid #dddddd;

  padding: 8px;
}

.tableHeader1 {
  background-color: #fafafa;
  border: 0px;

  padding: 15px;
}

.tableHeader1 td {
  background-color: #fafafa;
  border: 0px;

  padding: 15px;
}

.tableHeader td {
  border: 1px solid #dddddd;

  padding: 8px;
}

.btn-question {
  position: relative;
  border: none;
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(125, 122, 122, 0.3);

}

.btn-question {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-size: 15px;
  width: 100%;
}

.btn-question:hover {
  color: rgb(0, 0, 0);
}

/* .fullScreen{
  width:"100%"
} */

.water-mark-quiz {
  position: absolute;
  height: 95%;
  width: 100%;
  object-fit: contain;
  /* left: 20%; */
  opacity: 0.2;
  /* z-index: -1; */
}

.column-1 {
  height: 90vh;
}

@media only screen and (max-width: 600px) {
  .testCard {
    display: flex;
    justify-content: center;
  }

  .pdfviewer {
    padding: 10px 30px 10px 30px;
    /* display: flex; */

  }

  .column-1 {
    height: 90vh;
  }

}