.first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.collapse-nav {
  background-color: #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 2rem;
  border-radius: 8px;
  cursor: pointer;
  margin: 20px 0;
}

.collapse-nav>button {
  border: none;
  outline: none;
  background-color: transparent;
  color: #000;
  font-size: 18px;
  color: #7b1fa2;
  font-weight: 600;
  user-select: none;
}

.hidden-row {
  display: none !important;
}

.fourth-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.knowmore:hover {
  color: #f5f5f5 !important;
}

.course-switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-switch-container-sm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.selected-course {
  border: none;
  outline: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: 700;
}

.switch-course {
  border-radius: 4px;
  border: none;
  padding: 0.25rem 0.5rem;
  outline: none;
  /* color: #7b1fa2; */
  display: flex;
  gap: 1rem;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
}

.switch-course:disabled {
  background-color: white;
}

.switch-course-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  accent-color: #f316b0;
}

.overflow-hidden {
  overflow: hidden !important;
}

.course {
  display: flex;
  align-items: center;
}

.course>label {
  font-weight: 500;
}

.course>input {
  max-width: max-content;
}

.add-course {
  border: none;
  outline: none;
  background-color: transparent;
  color: #7b1fa2;
  font-weight: 500;
}

.tour-container {
  position: relative;
}

.tour-modal,
.tour-modal-2 {
  background-color: white;
  padding: 1rem;
  border-radius: 6px;
  max-width: 350px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  top: 50px;
  /* right: -200px; */
  right: auto;
  min-width: 350px;
}

.tour-modal-2 {
  right: 0;
  left: -160px;
}

.indicator,
.indicator-2 {
  height: 10px;
  width: 10px;
  background-color: white;
  position: absolute;
  top: -5px;
  transform: rotate(45deg);
}

.indicator-2 {
  right: 30px;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  position: absolute;
  inset: 0;
  z-index: 9998;
}

.special-max-w {
  max-width: 600px;
}

.live-section-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}

.live-section-heading {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 4px;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: #eaecec;
}

.video-series {
  overflow: auto;
  max-height: calc(100% - 60px);
}

.bg-logo {
  background: white url("../../Assets/images/translusent-logo.png") no-repeat center;
  background-size: contain;
}

.recorded-classes-container {
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.video-and-lock-container {
  position: relative;
}

.overlay-on-live-video-card {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.live-rec-video-details {
  display: flex;
  align-items: center;
}

.live-rec-video-expert-title>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.live-rec-video-expert-title>div>h5 {
  font-size: 14px;
  margin: 0;
}

.live-rec-video-expert-title>p {
  font-size: 12px;
  margin: 0;
}

.recorded-classes {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: max-content;
  gap: 10px;
  padding: 0.5rem;
  background-color: #eeebeba1;
  border-radius: 6px;
}

.exam-pattern-main {
  border: 1px solid #e1e1e1;
  overflow-x: auto !important;
  background: #fafafabb;
  border-radius: 5px;
}

.exam-pattern-thead {
  width: 25%;
  font-size: 14px;
  padding: 0.5rem;
  border: 1px solid #9e9e9e;
  text-align: center;
  background: #e1e1e1bb;
}

.exam-pattern-tdata {
  border: 1px solid #9e9e9e !important;
  text-align: center;
  font-size: 14px;
}

table,
tr,
td {
  border: none;
}

.third-row {
  max-width: 100% !important;
  padding-inline: 3rem;
  flex-wrap: wrap;
  background-color: #71269cb8;
}

.profile {
  padding: 8px 30px;
  flex: 1;
}

.profile-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex: 1;
  gap: 0.65rem;
}

.updateProfile:hover {
  color: #f5f5f5 !important;
}

.updateProfile {
  border: 1px solid #7b1fa2 !important;
  color: #7b1fa2 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px !important;
}

.card-img {
  max-width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 50%;
}

.practice-card,
.card-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 180px;
  height: 159px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 600;
  background-color: #ffffff;
}

.practice-card:hover {
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.25);
  transform: scale(1.05);
}

.group1:hover {
  background-color: #d1f6ff;
  border: 1px solid white;
}

.group2:hover {
  background-color: #e1fdf4;
  border: 1px solid white;
}

.group3:hover {
  background-color: #feffc5;
  border: 1px solid white;
}

.group4:hover {
  background-color: #fde3e1;
  border: 1px solid white;
}

.group5:hover {
  background-color: #e0ecfd;
  border: 1px solid white;
}

.group6:hover {
  background-color: #f7ecff;
  border: 1px solid white;
}

.group7:hover {
  background-color: #fbe5c9;
  border: 1px solid white;
}

.group8:hover {
  background-color: #64c0e7;
  border: 1px solid white;
}

.group9:hover {
  background-color: #ec8f7f;
  border: 1px solid white;
}

.group10:hover {
  background-color: rgba(240, 196, 138, 0.849);
  border: 1px solid white;
}

.group11:hover {
  background-color: rgb(237, 237, 222);
  border: 1px solid white;
}

.filter-btn {
  border: none;
  outline: none;
  color: black;
  padding: 0.5rem 1rem;
  min-width: 160px;
  background-color: transparent;
}

.seleted-filter {
  text-decoration: underline;
  text-underline-offset: 8px;
  color: #7b1fa2;
  font-weight: 500;
  text-decoration-thickness: 2px;
}

.completed-exam-table-container {
  max-height: 450px;
  overflow: auto;
}

.filter-btn-container {
  display: flex;
  /* max-width: calc(190px*3); */
  max-width: calc(214px * 3);
  overflow: auto;
}

.download-material-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.download-material-details {
  display: flex;
  flex: 1;
  padding: 0.5rem;
  flex-direction: column;
}

.download-topic-name {
  font-size: 17px;
  font-weight: 600;
}

.download-doc-name {
  font-size: 15px;
  color: #9e9e9e;
}

.fallback-message {
  max-width: max-content;
  margin: 2rem auto;
}

.fallback-message-classes-container {
  min-height: 240px;
  display: grid;
  place-items: center;
}

.classes-dropdown-btn {
  background-color: transparent;
}

.main-video-class-item-container {
  position: relative;
}

.overlay-on-video-card {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.details {
  min-width: 550px;
}

.live-classes-course-name {
  margin: 0;
  font-size: 14px;
}

.upcomming-classes-btn {
  font-size: 13px;
  font-weight: 500;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.upcomming-classes-status-btn-faded {
  background-color: #bcbcbd !important;
}

.upcomming-classes-status-btn-semi-faded {
  background-color: #b464d9 !important;
}

.upcomming-classes-status-btn {
  background-color: #b039e4 !important;
}

.upcomming-classes-join-btn {
  background-color: #7b1fa2 !important;
}

.upcomming-classes-status-btn-faded>span,
.upcomming-classes-status-btn-semi-faded>span,
.upcomming-classes-status-btn>span.add-course,
.upcomming-classes-join-btn>span {
  font-size: 15px;
}

.leaderboard-container {
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 500px;
  max-width: 350px;
  min-width: 300px;
}

.text-truncate {
  width: 120px;
  text-align: end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.most-pts-heading {
  color: #7b1fa2;
  font-size: 24px;
}

.leader-profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.leader-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
}

.leader-badge {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #7b1fa2;
  color: #fff;
  display: grid;
  place-items: center;
  font-size: 20px;
  position: absolute;
  right: 5px;
  bottom: -5px;
}

.first-rank-holder {
  font-weight: 500;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  gap: 0.75rem;
}

.first-rank-holder>.points {
  color: #7b1fa2;
  font-size: 18px;
}

.rank-holders-container {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  font-weight: 500;
  color: #000;
  max-height: 175px;
  overflow: auto;
  width: 100%;
}

.rank-holder {
  display: flex;
  gap: 1rem;
  background-color: #b464d9;
  padding: 0.75rem;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6f9;
  border-radius: 8px;
}

.runner-up-profile {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.rank {
  color: #211791;
  font-size: 12px;
  font-weight: 900 !important;
}

.my-rank {
  border: 1px solid #ffa02c;
  background-color: #fff6eb;
}

.rank-holder-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.points {
  font-weight: 700 !important;
}

.view-more-leaders {
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 700;
  color: #71269c;
}

.view-more-leaders:hover,
.view-more-leaders:focus {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2px;
  text-decoration: none;
}

.progress-chart-container {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  height: 500px;
}

.chart-container {
  height: 90%;
  width: 98%;
  margin: auto;
}

.chart-container>canvas {
  min-height: 420px;
}

.graph-fallback {
  height: 425px;
  display: grid;
  place-items: center;
}

@media (max-width: 1000px) {
  .special-max-w {
    max-width: none;
  }

  .first-row,
  .fourth-row {
    flex-direction: column;
  }

  .progress-chart-container {
    width: 100%;
  }

  .profile {
    min-width: 100% !important;
    justify-content: center;
    padding-right: 8px;
    padding-left: 8px;
  }

  .profile-details {
    flex: 0;
  }

  .message {
    min-width: 100% !important;
  }

  .second-row {
    flex-direction: column;
  }

  .details {
    min-width: 100% !important;
  }
}

@media (max-width: 645px) {
  .modal-body-main-test {
    flex-direction: column;
    gap: 25px;
  }

  .update-Remainder {
    flex-direction: column;
  }

  .course-switch-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .switch-course {
    padding: 0.5rem 0.25rem;
  }

  .selected-course {
    padding: 0.5rem 0;
  }

  .tour-modal {
    top: 60px;
    left: 0;
  }

  .tour-modal-2 {
    left: 0;
    right: -150px;
    top: 55px;
  }

  .indicator-2 {
    left: 20px;
  }
}

@media (max-width: 550px) {
  .recorded-classes {
    flex-direction: column;
  }
}