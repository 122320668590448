.ctet_question {
    background-color: #5a1e7d;
    mix-blend-mode: multiply;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: auto;
    flex-shrink: 0;
    position: relative;
    padding: 10px;
    border-radius: 10px;
}

.ctet_join {
    background-color: #17081f;
    mix-blend-mode: multiply;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: auto;
    flex-shrink: 0;
    position: relative;
    padding: 50px;
    border-radius: 10px;
}



.ctet_syllabus {
    border: #5a1e7d 1px solid;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px;
}

.intro_ctet {
    font-size: 18px;
    padding: 10px;
}

.intro_ctet_card {
    background-color: #5a1e7d;
}

.match_height {
    height: 100%;
}

/* join call to action */
.join_img_ctet {
    width: 100%;
    position: relative;
    /* margin-top: 60px; */
    padding: 66px 0 39px;
    text-align: center;
    background: url(../../../../public/assets/join_img/background_join.jpg) no-repeat fixed 50% 0;
    background-size: cover;
    color: white;
    /* Ensure text is white to contrast with the dark overlay */
}

.join_img_ctet::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(3, 3, 3, 0.5);
    z-index: 1;
    /* Ensure overlay is on top of the image */
}

.reg_content_ctet {
    position: relative;
    z-index: 2;
    /* Ensure content is on top of the overlay */
}

.fa-circle-play_ctet {
    color: white;
}

.fa-circle-play_ctet:hover {
    color: #5a1e7d;
}

.about_us {
    background-color: #ffff;
    padding: 20px;
    border: 1px solid #000;
    border-radius: 15px
}


.title_key {
    width: 100%;
    font-weight: 600;
    font-size: 23px !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.Ctet_Course_card {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 1px solid var(--Grey-9, #e0e0e0);
    background: #fff;
    font-size: 13px;
    overflow: hidden;
    padding: 10px;
    margin-left: 10px;
    /* margin-top: 30px; */
    height: 100%;
    width: 100%;
}

.theme-title-one {
    font-size: 20px;
}

.feature-block-one {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* CustomCard.css */
.custom-card {
    border: none;
    /* Remove card border */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Add shadow for depth */
}

.custom-card-body {
    padding: 10px;
    padding-bottom: 0px !important;
    position: relative;
}

.red-banner {
    background-color: #5a1e7d;
    color: white;
    height: 50px;
    padding: 18px;
    position: relative;
    border-radius: 0 60px 0px 0px;
    width: 100%;
    margin-left: -92px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.red-banner h5 {
    margin: 0;
}

.custom-card .red-banner::before {
    content: '';
    rotate: 90deg;
    position: absolute;
    top: 46px;
    left: 4px;
    width: 3px;
    height: 20px;
    border-top: 28px solid #5a1e7d;
    border-right: 20px solid transparent;
}


@media only screen and (max-width: 767px) {

    .red-banner {
        background-color: #5a1e7d;
        border-radius: 0 60px 0 0;
        box-shadow: 0 7px 29px 0 #64646f33;
        color: #fff;
        height: 50px;
        margin-left: -10px;
        padding: 18px;
        position: relative;
        width: 100%;
    }

    .custom-card .red-banner:before {
        /* border-right: 23px solid #0000; */
        /* border-top: 28px solid #5a1e7d; */
        content: "";
        height: 30px;
        left: 2px;
        position: absolute;
        /* rotate: 360deg; */
        top: 46px;
        width: 3px;
    }

    .join_img_ctet {
        width: 100%;
        position: relative;
        /* margin-top: 60px; */
        padding: 1px 14px 31px;
        text-align: center;
        background: url(../../../../public/assets/join_img/background_join.jpg) no-repeat fixed 50% 0;
        background-size: cover;
        color: white;
        /* Ensure text is white to contrast with the dark overlay */
    }
}


.hone_size {

    font-size: 2em !important;
}

.htwo_size {
    font-size: 1.5em !important;
    color: black;
}

.hthree_size {
    font-size: 1.17em !important;
}

.hfour_size {
    font-size: 1em !important;
}

.hfive_size {
    font-size: .83em !important
}

.hsix_size {
    font-size: .67em
}

.payment-summary strong {
    color: #000 !important;
}