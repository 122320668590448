@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  scrollbar-width: 1px !important;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

.slider {
  width: 100%;
  transition: all 0.8s;
}

.header {
  margin: 50px auto;
  text-align: center;
  font-size: 80px;
}

.slide {
  width: 100%;
  transition: all 0.8s;
}

.slide {
  /* opacity: .5; */
  /* transform: scale(0.9);
  transition: all 0.8s;
  transition-delay: 1.2s;
  transition-timing-function: ease-out; */
  /* filter: blur(5px); */
}

.slide-width {
  border-radius: 10px;
  max-width: 600px;
  transition: 0.5s;
  /* background: rgb(70, 66, 66); */
}

.slide-active {
  /* filter: blur(0); */
  opacity: 1;
  transform: scale(0.95);
  transition-delay: 1.2s;
  transition-timing-function: ease-out;
  border: 1px solid #7b1fa2;
  border-radius: 10px;
  height: "300px";
}

.arrow {
  font-size: 30px;
  padding: 5px 15px;
  border-radius: 10px;
  box-shadow: 0 0 5px 3px #ccc;
  display: flex;
  width: 50px;
  position: absolute;
  bottom: -50px;
  z-index: 100;
  cursor: pointer;
}

.arrow-right {
  right: 50%;
  transform: translateX(100%);
}

.arrow-left {
  left: 50%;
  transform: translateX(-100%);
}

.dot {
  margin-top: 20px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0 0 5px 3px #ccc;
}

.dot-active {
  background: #121227;
  box-shadow: 0 0 15px 10px #ccc;
}

.nav-tabs .nav-link.active {
  color: rgb(11, 0, 0);
  background-color: #fff;
  border-color: #dee2e6 #dee2e6;
}

.nav-tabs .nav-link {
  color: gray;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-item button {
  font-size: 16px;
  font-weight: 600;
  line-height: 2px;
}

.main-course-details-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.course-details-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-details-header-links-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
}

.header-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.course-slide-container {
  border: 1px solid purple;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: max-content;
  max-height: 700px;
  overflow-y: auto;
  background-color: white;
}

.course-details-tabs-container {
  padding: 0.5rem;
  flex-wrap: nowrap;
  gap: 0.15rem;
  overflow: auto;
}

.course-details {
  border-radius: 6px;
  border: 1px solid #CCCCCC;
  overflow: hidden;
  flex: 1;
}

.external-course-details-links-container {
  background-color: #ffffff;
  border: 1px solid #CCCCCC;
  border-radius: 6px;
  padding: 2rem 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: max-content;
}

.external-course-details-links {
  color: #7B1FA2;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

.not-available-fallback {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cousre-details-tabs-container {
  overflow: auto;
}

.toggle-external-links-btn {
  border: none;
  background-color: #7B1FA235;
  color: #7B1FA2;
  padding: 0rem 0.5rem;
  border-radius: 6px;
  font-size: 29px;
  line-height: 1.06;
}

@media (max-width: 870px) {
  .course-details-header-container {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .header-buttons-container {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  .external-course-details-links-container {
    padding: 1rem;
  }
}

@media (max-width: 760px) {
  .course-details-header-links-container {
    flex-direction: row;
  }

  .external-course-details-links-container {
    padding: 0.35rem;
  }

  .main-course-details-container {
    gap: 0.5rem;
  }
}