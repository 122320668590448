.circlespan {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  border: 1px solid #080808;
  overflow: hidden;
  background-color: white;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.imagectn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.textctn {
  gap: 160px;
}

.textctn2 {
  justify-content: space-between;
}

.providerbtn {
  flex: 0.3;
  height: 50px;
  border-radius: 5px;
  color: var(--gray-900, #1d2026);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border: 1px solid var(--gray-100, #e9eaf0);
  background: var(--gray-white, #fff);
  border-radius: 1px;
  margin: 2px;
}

.providerbtn:hover {
  border: 1px solid #1627c755;
  border-radius: 1px;
}
