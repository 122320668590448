.btnFacebook {
  content: "";
  width: 50px;
  height: 50px;
  font-size: 0px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0px transparent;
  text-align: center;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.btnFacebook .fa {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: white;
}

.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}
