.btn-container {
   display: flex;
   align-items: center;
   justify-content: flex-end;
   gap: 1rem;
   margin-top: 1rem;
}

.btn-mcq {
   background-color: #7b1fa2;
   color: #ffffff;
   border: none;
   padding: 0.5rem 1.75rem;
   border-radius: 6px;
   font-size: 17px;
   cursor: pointer;
}

.btn-mcq:hover {
   opacity: 0.95;
   transform: scale(1.04);
}

.mcq-alternate-color>div:nth-child(even) {
   background-color: #810cb2 !important;
   font-weight: 400;
   border-radius: 10px;
   color: white;
}

.mcq-alternate-color>div:nth-child(odd) {
   color: #45115c !important;
   background-color: white;
}

.exam-title {
   text-align: center;
   color: #7b1fa2;
   background-color: white;
   font-weight: bold;
}

.regForm {
   background-color: #ffffff;
   padding: 2rem;
   border-radius: 15px;
   border: 1px solid #7b1fa2;
   border-top: 10px solid #7b1fa2;
}

.main-container {
   display: flex;
   justify-content: center;
   align-items: flex-start;
   gap: 1.5rem;
   max-width: 98%;
   margin: 30px auto auto;
}

.test-container{
   flex: 1;
}

.test-status-container {
   background-color: #f7f7f7;
   max-width: 23%;
   min-width: 300px;
   padding: 0.75rem;
   border-radius: 6px;
   border: 1px solid #e9e9e9;
   position: sticky;
   top: 100px;
}

.status-details-container {
   display: flex;
   flex-direction: column;
   gap: 5px;
   font-size: 17px;
}

.status-line {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.status-value {
   display: inline-block;
   font-weight: 600;
   min-width: 50%;
}

.question-btn-container {
   margin-top: 1rem;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 5px;
   padding-block: 0.5rem;
   max-height: 200px;
   overflow: auto;
   overflow-x: hidden;
}

.light-bg-img {
   background-image: url("../../Assets/images/translusent-logo.png");
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
}

.dark-bg-img {
   background-image: url("../../Assets/images/translusent-white.png");
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   overflow: hidden;
}

.clear-bookmark {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
}

.bookmark-question>p {
   font-size: 14px;
   background: #919191;
   width: fit-content;
   padding: 3px 5px;
   border-radius: 5px;
   cursor: pointer;
   color: rgb(255, 255, 255);
}

.clear-mcq-selection {
   margin: 10px 20px 0;
   cursor: pointer;
   border-radius: 6px;
   text-decoration: underline;
}

.clear-mcq-selection:hover {
   text-decoration: none;
}

.positioning-of-flag {
   position: absolute;
   left: -30px;
   top: 15px;
}

.explaination-card {
   min-width: 100%;
   min-height: auto;
   border-radius: 10px;
   padding-inline: 12px;
}

.explaination-card:hover {
   transform: scale(1.02);
   box-shadow: 0 0 16px -2px #e4e4e4;
}

span.review-option>* {
   margin: 0 !important;
}

.status-details-container-dropdown,
.dropdown-btn {
   display: none;
}

/* Genel stil */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin: 10px;
}

/* Giriş stil */
.toggle-switch .toggle-input {
  display: none;
}

/* Anahtarın stilinin etrafındaki etiketin stil */
.toggle-switch .toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 24px;
  background-color: #959595;
  border-radius: 34px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Anahtarın yuvarlak kısmının stil */
.toggle-switch .toggle-label::before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  background-color: rgb(247, 247, 247);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s;
}

/* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
.toggle-switch .toggle-input:checked + .toggle-label {
  background-color: #810cb2;
}

.toggle-switch .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}

/* Light tema */
.toggle-switch.light .toggle-label {
  background-color: #BEBEBE;
}

.toggle-switch.light .toggle-input:checked + .toggle-label {
  background-color: #9B9B9B;
}

.toggle-switch.light .toggle-input:checked + .toggle-label::before {
  transform: translateX(6px);
}

/* Dark tema */
.toggle-switch.dark .toggle-label {
  background-color: #4B4B4B;
}

.toggle-switch.dark .toggle-input:checked + .toggle-label {
  background-color: #717171;
}

.toggle-switch.dark .toggle-input:checked + .toggle-label::before {
  transform: translateX(16px);
}

@media(max-width: 900px) {
   .main-container {
      flex-direction: column-reverse;
      align-items: center;
      max-width: 100%;
      margin: 0 auto 30px;
   }

   .status-details-container {
      display: none;
   }

   .question-btn-container {
      margin: 0;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      min-width: 100% !important;
      position: relative;
      padding: 0.5rem;
   }

   .test-status-container {
      top: 78px;
      flex-grow: 5;
      min-width: 100% !important;
      z-index: 9;
   }

   .regForm {
      padding: 1rem;
   }

   .dropdown-btn {
      position: absolute;
      right: 10px;
      font-size: 20px;
      color: white;
      bottom: -40px;
      height: 40px;
      width: 45px;
      background-color: green;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0 0 50px 50px;
   }

   .status-details-container-dropdown {
      width: max-content;
      min-width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem auto;
   }

   .status-value {
      min-width: 40%;
   }
}