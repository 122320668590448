/* ======================== */
/*   Syed Sahar Ali Raza   	*/
/* ========================	*/
@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);

#generic_price_table {
  background-color: #f0eded;
}

/*PRICE COLOR CODE START*/
#generic_price_table .generic_content {
  background-color: #fff;
}

#generic_price_table .generic_content .generic_head_price {
  background-color: #f6f6f6;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
  border-color: #e4e4e4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e4e4e4;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head span {
  color: #525252;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
  color: #414141;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  color: #a7a7a7;
}

#generic_price_table .generic_content .generic_feature_list ul li span {
  color: #414141;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
  background-color: #e4e4e4;
  border-left: 5px solid #7b1fa2;
}

#generic_price_table .generic_content .generic_price_btn a {
  border: 1px solid #7b1fa2;
  color: #7b1fa2;
}

#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg {
  border-color: #7b1fa2 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #7b1fa2;
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head span,
#generic_price_table .generic_content.active .generic_head_price .generic_head_content .head span {
  color: #fff;
}

#generic_price_table .generic_content:hover .generic_price_btn a,
#generic_price_table .generic_content.active .generic_price_btn a {
  background-color: #7b1fa2;
  color: #fff;
}

.row .table {
  padding: 28px 0;
}

/*PRICE BODY CODE START*/

#generic_price_table .generic_content {
  overflow: hidden;
  position: relative;
  text-align: center;
}

#generic_price_table .generic_content .generic_head_price {
  margin: 0 0 20px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content {
  margin: 0 0 50px 0;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg {
  border-style: solid;
  border-width: 90px 1411px 23px 399px;
  position: absolute;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head {
  padding-top: 40px;
  position: relative;
  z-index: 1;
}

#generic_price_table .generic_content .generic_head_price .generic_head_content .head p {
  font-family: "Raleway", sans-serif;
  font-size: 28px;
  font-weight: 400;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag {
  padding: 0 0 20px;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price {
  display: block;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .sign {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 400;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .currency {
  font-family: "Lato", sans-serif;
  font-size: 60px;
  font-weight: 300;
  letter-spacing: -2px;
  line-height: 60px;
  padding: 0;
  vertical-align: middle;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .price .cent {
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 24px;
  font-weight: 400;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_head_price .generic_price_tag .month {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 3px;
  vertical-align: bottom;
}

#generic_price_table .generic_content .generic_feature_list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#generic_price_table .generic_content .generic_feature_list ul li {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  padding: 15px 0;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li:hover {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}

#generic_price_table .generic_content .generic_feature_list ul li .fa {
  padding: 0 10px;
}

#generic_price_table .generic_content .generic_price_btn {
  margin: 20px 0 32px;
}

#generic_price_table .generic_content .generic_price_btn a {
  border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  -webkit-border-radius: 50px;
  display: inline-block;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  outline: medium none;
  padding: 12px 30px;
  text-decoration: none;
  text-transform: uppercase;
}

#generic_price_table .generic_content,
#generic_price_table .generic_content:hover,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head_bg,
#generic_price_table .generic_content .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content:hover .generic_head_price .generic_head_content .head h2,
#generic_price_table .generic_content .price,
#generic_price_table .generic_content:hover .price,
#generic_price_table .generic_content .generic_price_btn a,
#generic_price_table .generic_content:hover .generic_price_btn a {
  transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 767px) {
  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #generic_price_table .col-md-3 {
    float: left;
    width: 50%;
  }

  #generic_price_table .col-md-4 {
    float: left;
    width: 50%;
  }

  #generic_price_table .generic_content {
    margin-bottom: 75px;
  }
}

#generic_price_table_home {
  font-family: "Raleway", sans-serif;
}

.text-center h1,
.text-center h1 a {
  color: #7885cb;
  font-size: 30px;
  font-weight: 300;
  text-decoration: none;
}

.demo-pic {
  margin: 0 auto;
}

.demo-pic:hover {
  opacity: 0.7;
}

#generic_price_table_home ul {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: table;
}

#generic_price_table_home li {
  float: left;
}

#generic_price_table_home li+li {
  margin-left: 10px;
  padding-bottom: 10px;
}

#generic_price_table_home li a {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0px;
}

#generic_price_table_home .blue {
  background: #3498db;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .emerald {
  background: #7b1fa2;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .grey {
  background: #7f8c8d;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .midnight {
  background: #34495e;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .orange {
  background: #e67e22;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .purple {
  background: #9b59b6;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .red {
  background: #e74c3c;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .turquoise {
  background: #7b1fa2;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .blue:hover,
#generic_price_table_home .emerald:hover,
#generic_price_table_home .grey:hover,
#generic_price_table_home .midnight:hover,
#generic_price_table_home .orange:hover,
#generic_price_table_home .purple:hover,
#generic_price_table_home .red:hover,
#generic_price_table_home .turquoise:hover {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  transition: all 0.3s ease-in-out 0s;
}

#generic_price_table_home .divider {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 20px;
}

#generic_price_table_home .divider span {
  width: 100%;
  display: table;
  height: 2px;
  background: #ddd;
  margin: 50px auto;
  line-height: 2px;
}

#generic_price_table_home .itemname {
  text-align: center;
  font-size: 50px;
  padding: 50px 0 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  text-decoration: none;
  font-weight: 300;
}

#generic_price_table_home .itemnametext {
  text-align: center;
  font-size: 20px;
  padding-top: 5px;
  text-transform: uppercase;
  display: inline-block;
}

#generic_price_table_home .footer {
  padding: 40px 0;
}

.price-heading {
  text-align: center;
}

.price-heading h1 {
  color: #666;
  margin: 0;
  padding: 0 0 50px 0;
}

.demo-button {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  outline-color: -moz-use-text-color;
  outline-style: none;
  outline-width: medium;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}

.bottom_btn {
  background-color: #333333;
  color: #ffffff;
  display: table;
  font-size: 28px;
  margin: 60px auto 20px;
  padding: 10px 25px;
  text-align: center;
  text-transform: uppercase;
}

.demo-button:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.bottom_btn:hover {
  background-color: #666;
  color: #fff;
  text-decoration: none;
}

.pack-p {
  font-size: 15px;
  color: #7b1fa2;
  white-space: nowrap;
  font-weight: bold;
  margin-left: 40px;
  text-align: left;
}

/* CSS */

#payment-block input[checked]+label {
  background-color: #7b1fa2 !important;
  border-color: #7b1fa2 !important;
  color: white !important;
}

/* label:hover {
  background-color: #7b1fa2 !important;
  border-color: #7b1fa2 !important;
  color: white !important;
} */

/* Address Styles Starts */

.payment_confirm {
  width: 35%;
  margin: 0 auto;
  background: #ffffff;
  padding: 50px;
  box-shadow: 0 0px 3px 3px #cdcdcd4d;
  border-radius: 8px;
}

.view_details {
  color: #7b1fa2;
  border: none;
  background: none;
  outline: none;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
}

.payment_details p {
  font-size: 1rem;
}

/* subscription modal/card */
.subscription-card {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  padding: 0;
  text-align: center;
  min-height: 715px;
  max-width: 325px;
  min-width: 325px;
}

.subscription-card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #000;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.card_body {
  position: relative;
  padding: 1.5rem;
  font-size: 0.835rem;
  line-height: 1.2rem;
}


.features {
  font-weight: bold;
  margin: 0.5rem 0;
  color: #542567;
  cursor: pointer;
}

.features:hover {
  text-decoration: underline;
}


.price_chart {
  background: #f8f1fb;
  padding: 0.6rem 0.5rem;
  border-radius: 4px;
}

.price_chart p {
  color: #000;
  font-weight: 300;
  margin: 0;
}

.price_chart span {
  font-weight: 500;
}

.discount_col {
  color: #f71873;
  font-weight: bold;
}

.outline-btn {
  color: #7b1fa2;
  font-size: 0.835rem;
  line-height: 1rem;
  background: #fff;
  border: 1px solid #7b1fa2;
  border-radius: 4px;
  width: 100%;
  padding: 0.65rem 1.25rem;
  text-align: left;
  /* background-image: url("../../Assets/right-arrow.svg"); */
  background-repeat: no-repeat;
  background-position: 95%;
}

.card_footer {
  background: #fafafa;
  border-top: 1px solid #e1e1e1;
  padding: 1.25rem;
  margin-top: auto;
  min-height: 5.625rem;
  /* display: flex; */
  align-items: center;
}

.card_footer span {
  font-size: 0.65rem;
  font-weight: bold;
}

.card_footer p span {
  font-size: 0.7rem;
  font-weight: 500;
}

.card_footer .main-btn {
  text-transform: uppercase;
}

.card_footer .main-btn:disabled {
  font-size: 0.8rem;
  white-space: break-spaces;
  flex-basis: 50%;
}

#subModal .modal-title {
  font-size: 1.25rem;
}

.pack_inclusion {
  padding: 0.65rem;
  /* background: #f5f5f5; */
  /* border: 1px solid #e1e1e1; */
  border-radius: 4px;
  text-align: left;
  margin: 1rem 0;
}

.pack_inclusion p {
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
}

.pack_inclusion ul {
  margin: 0;
  padding: 0.75rem 0 0.75rem 1.25rem;
  list-style-image: url('../../Assets/Vector.svg')
}

.pack_inclusion li {
  font-size: 0.85rem;
  padding: 0.25rem 0;
}

.pack_inclusion li:last-child {
  margin: 0;
}

.pack_inclusion li .pack_img {
  position: absolute;
  top: -2px;
  left: 0;
}

.flex_gap {
  row-gap: 1.25rem;
  column-gap: 1.25rem;
}

.domain_sub {
  color: #542567;
  background: #ffffff;
  border: 1px solid #542567;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.domain_sub.active {
  color: #fff;
  background: #542567;
  border: 1px solid #542567;
}

.disable .domain_sub:not(.active) {
  color: #9e9e9e;
  background: #ffffff;
  border: 1px solid #9e9e9e;
  pointer-events: none;
}

.domain_sub:disabled {
  color: #000 !important;
  background: #d6d6d6 !important;
  border: 1px solid #d6d6d6 !important;
  pointer-events: none;
}

.alertTxt {
  color: #f00;
  font-size: 0.835rem;
}

.warning_txt {
  display: none;
}

.disable+.warning_txt {
  display: block;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.ribbon-pop {
  background: linear-gradient(270deg, #7b1fa2 5.25%, #576bb0 96.68%);
  display: inline-block;
  padding: 0.2rem 2rem 0.2rem 1rem;
  color: white;
  position: absolute;
  left: -4px;
  top: 10px;
  font-size: 0.835rem;
}

.ribbon-pop:after {
  content: "";
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
  right: -1px;
  border-right: 13px solid #fff;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
}

.ribbon-pop:before {
  height: 0;
  width: 0;
  border-top: 5px solid #381f57;
  border-left: 5px solid transparent;
  bottom: -5px;
  position: absolute;
  content: "";
  left: -1px;
}

.back-btn {
  background-color: transparent;
  border: none;
  color: #7b1fa2;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 1;
}

.back-btn:hover {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}


.courses-tab-container {
  display: flex;
  gap: 2rem;
  overflow: auto;
  /* border-bottom: 2px solid #868686; */
}

.subscription-tab-item {
  padding: 0.4rem 1.5rem;
  min-width: max-content;
  font-size: 13px;
  font-weight: 700;
  margin: 0.25rem 0;
  color: #868686;
  cursor: pointer;
  border-radius: 8px;
}

.subscription-tab-item-active {
  color: #7b1fa2;
  background-color: #7b1fa250;
}

.fallback-message-subscriptions-container {
  min-height: 70vh;
  display: grid;
  place-items: center;
}

.address-container{
   border-radius: 8px;
   display: flex;
   flex-direction: column;
   overflow: hidden;
}

.address-line {
   background-color: rgb(240, 240, 240);
   display: flex;
   justify-content: space-between;
   padding: 0.35rem 2rem;
   margin: 0;
   gap: 0.75rem;
}

.address-line:nth-child(odd) {
   background-color: rgb(230, 230, 230);
}

.modal-profile-label {
   font-weight: 600;
   margin-bottom: 3px !important;
}

.modal-profile-fields{
  padding: 0.5rem ;
   border-radius: 6px;
   width: 100% !important;
   max-height: 150px;
}

.payment-block-container{
  display: flex;
  gap: 2rem;
}

.coupon-container{
  flex: 1;
}

.coupon-fields-container{
  display: flex;
  gap: 2rem;
}

.coupon-fields-container>div{
  flex: 1;
}

.payment-summary-container{
  flex: 1
}

.payment-summary{
  border: 1px solid #d6d6d6;
  border-radius: 6px;
  padding: 1rem;
}

.summary-heading{
  font-size:20px;
	color: #7b1fa2;
	font-weight:bold;
}

.payment-summary p{
  margin: 0 !important;
}

.billing-summary-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.for-m-0 p{
  margin: 0;
}

@media (max-width: 995px){
  .payment-block-container{
    flex-direction: column;
  }

  .billing-summary-container{
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
  }
}

/*invoice css*/

.invoice-page{
    margin: 100px 0 0 0;
    padding: 50px;
    color: #7B1FA2;
    display: flex;
    flex-direction: column;
    
}
.invoice-page > h1{
    display: block;
    font-size: 35px;
    margin: auto;
}

.invoice-page > a{
    margin: auto;
    margin-top: -85px;
    margin-bottom: 20px;
}
.invoice-page > .invoice-total-amount{
    margin-left: auto;
}
.invoice-page >table ,td ,tr{
    border: 1px solid black;
    padding: 3px;
  }
  
  /* overlay css */

  .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 2;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.overlay h1 {
  font-size: 4rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 630px) {
  .overlay h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 450px) {
  .overlay h1 {
    font-size: 2.5rem;
  }
}
.down-arrow {
  position: absolute;
  bottom: 20;
  left: 50%;
  transform: translateX(-50%);
  width: 90px; /* Adjust the width as needed */
  height: auto;
  background-color: #ADD8E6;
}

